@mixin when-screen-smaller($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin when-screen-bigger($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

// Font for title.
// https://google-webfonts-helper.herokuapp.com/fonts/lato?subsets=latin
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/assets/lato-v20-latin-regular.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
       url('/assets/lato-v20-latin-regular.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
  font-display: swap;
}

// Don't cut off snippets when printing.
@media print {
  body {
    width: 900px;
  }
}

// Don't show when printing.
.no-print {
  @media print {
    display: none;
  }
}

// Don't show when not printing.
.no-screen {
  @media screen {
    display: none;
  }
}

// Disable selection with the mouse.
.no-select {
  // https://stackoverflow.com/a/4407335
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -khtml-user-select: none; // Konqueror HTML
  -moz-user-select: none; // Old versions of Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Chrome, Edge, Opera and Firefox
}

// Don't overflow screen or container.
// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
.no-overflow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

// Emojis.
img.emoji {
  height: 1.1em;
  width: 1.1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.15em;
}

// Cover images.

.cover-wrapper {
  display: none;
  @include when-screen-smaller(1200px) {
    display: block;
  }
  @media print {
    display: block;
  }
}

.wide-cover-wrapper {
  position: relative;
  z-index: 1;

  @include when-screen-smaller(1200px) {
    display: none;
  }
  @media print {
    display: none;
  }
}

.wide-cover-title-wrapper {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.wide-cover-title {
  padding: 10px;
  display: inline-block;
}

// Table of contents.

.block-toc-wrapper {
  background: #f4f4f4;
  margin: 25px 10px 25px 10px;
  padding-top: 15px;
  padding-bottom: 1px;
  border-radius: 5px;

  display: none;
  @include when-screen-smaller(1500px) {
    display: block;
  }
  @media print {
    display: block;
  }
}

.side-toc-reminder {
  @include when-screen-smaller(1500px) {
    display: none;
  }
  @media print {
    display: none;
  }
}

.side-toc-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  margin-top: 15px;
  float: left;

  @include when-screen-smaller(1500px) {
    display: none;
  }
  @media print {
    display: none;
  }
}

#table-of-contents a {
  color: grey;
}

a.toc-active {
  font-weight: bold;
}

// Frames.

.frame {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 16px;
}

.frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  border: 0;
}

// Social icons.

div.social-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}

img.social-logo {
  width: 60px;
  padding: 10px;
  z-index: 1;
}

div.social-extra-wrapper {
  // Allow absolute positioning for img.
  position: relative;
}

img.social-extra {
  width: 24px;
  position: absolute;
  top: 55px;
  left: 55px;
  z-index: 2;
}

// Quotes.

table.quote {
  border: 0;
  position: relative;
  left: -5%;
  width: 105%;
  margin-bottom: 10px;
  background: white;
}

tr.quote {
  border: 0;
  background: white;
}

th.quote_bracket {
  font-family: "Lato";
  border: 0;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 300%;
  width: 1%;
  min-width: 30px;
  white-space: nowrap;
  vertical-align: middle;
  text-align: right;
  color: #999;
  background: white;
}

th.quote_text {
  border: 0;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
  font-style: italic;
  color: #555;
  background: white;
}

th.quote_source {
  border: 0;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
  text-align: right;
  font-size: 87.5%;
  color: black;
  background: white;
}

th.quote_corner {
  background: white;
}

// Notes.

div.block-note {
  font-style: italic;
  border-left: solid 2px #999;
  color: #555;
  margin: 20px 0 20px;
  padding: 0 20px;
}

div.side-note-wrapper {
  position: relative;
}

div.side-note {
  display: block;
  position: absolute;
  float: right;
  height: 170px;
  width: 170px;
  right: -220px;
  font-size: 87.5%;
}

// Content switches.
// https://www.w3schools.com/howto/howto_css_switch.asp

.switch {
  margin-top: -12px;
  float: right;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #cec;
}

input:focus + .slider {
  box-shadow: 0 0 1px #cec;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

// Content selector.

@include when-screen-bigger(600px) {
  .selectors-wrapper {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    float: right;
    margin-top: -60px;
  }
}

@include when-screen-smaller(600px) {
  .selectors-wrapper {
    // padding-bottom: 15px;
    display: none;
  }
}

.selector-wrapper {
  display: inline-block;
  text-align: center;
  width: 140px;
  height: 50px;
  line-height: 50px;
}

.selector-wrapper input[type="radio"] {
  display: none;
}

.selector-wrapper label {
  display: inline-block;
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  background: #e5f1ff;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
}

.selector-wrapper input[type="radio"]:checked+label {
  background: #09f;
  color: #fff;
}
